import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { useMutation } from 'react-query';
import axios from 'axios';

// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------
const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().min(8).max(32).required(),
  })
  .required();

export default function LoginForm() {
  const { mutate, isLoading } = useMutation(
    (post) => axios.post('https://uhsapi.dev-sh.xyz/api/login', post),
    {
      onSuccess: async (data) => {
        localStorage.setItem('access_token', data?.data?.data?.access_token);
        toast.success(data?.data?.data?.message);
        console.log(data?.data?.data?.message,'response')
        handleClick();
      },
      onError: (error) => {
        
        toast.error(error?.response?.data?.message);
        console.log(error?.response?.data?.message,'error')
      },
    }
  );

  // console.log(error,"error")
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    mutate(data);
  };
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    navigate('/dashboard', { replace: true });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Toaster />
        <Stack spacing={3}>
          <TextField name="email" {...register('email')} label="Email address" />
          <p className="error-msg">{errors.email?.message}</p>

          <TextField
            name="password"
            {...register('password')}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <p className="error-msg">{errors.password?.message}</p>
        </Stack>
        <br />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
          Login
        </LoadingButton>
      </form>
    </>
  );
}
