import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  height: '90vh',
  overflowY: 'scroll',
  p: 3,
};
// eslint-disable-next-line
export default function Modals({ news }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <RemoveRedEyeIcon />
        &nbsp; View Details
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            How many square feet is the area you want to develop?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.square_feet
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Does your basement stairwell need to be developed?
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.stairwell_basement
              }
            </span>
          </Typography>
          <hr />

          <Typography id="modal-modal-title" variant="h6" component="h2">
            How may bedrooms/office space?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.bedrooms
              }
            </span>
          </Typography>
          <hr />

          <Typography id="modal-modal-title" variant="h6" component="h2">
            How many rec rooms/general gathering areas?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.rec_rooms
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            How many bathrooms?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.bathrooms
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
          below to enter?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.below_to_enter
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Enter to Win 4 Tickets to The Oilers VS Golden Knights April 10, 2024?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.win_4_tickets
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
           If Your Basement is Undeveloped, Enter to Win a Free Basement Layout Plan (up to 1500 square feet)?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.basement_is_undeveloped
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            How many interior doors? (count double doors as 2){' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.doors
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want a bar/kitchen?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.bar_or_kitchen
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want a fireplace?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.fireplace
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you wanting a legal secondary suite for rental purposes?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.legal_sec_suit
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you have an 8-foot or 9-foot basement ceiling?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.basement_foot
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            How many walk-in closets or finished storage rooms?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.storage_rooms
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            How Many Basement Windows?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.basement_windows
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            What is the approximate year of your home?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.approximate_year
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Any special notes you would like to mention?{' '}
            <span className="text-red">
              {
                // eslint-disable-next-line
                news.special_notes
              }
            </span>
          </Typography>
          <hr />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            TOTAL COST :
            <span className="text-red">
              $
              {
                // eslint-disable-next-line
                news.quote_price
              }
            </span>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
