import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { Circles } from 'react-loader-spinner';
import LoadingButton from '@mui/lab/LoadingButton';
// @mui
import { Container, Stack, Typography, Button, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import MyEditor from './MyEditor';
import { config, baseUrl } from './header';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const [addPdfLoading, setAddPdfLoading] = useState(false);
  const [addLinkLoading, setAddLinkLoading] = useState(false);
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [removeLinkLoading, setRemoveLinkLoading] = useState(false);
  const [removePdfLoading, setRemovePdfLoading] = useState(false);
  const [link, setLink] = useState('');

  const [editorData, setEditorData] = useState('');
  const getEditor = (data) => {
    console.log(data,"data");
    setEditorData(data);
  };


  const getFacts = async () => {
    const res = await fetch(`${baseUrl}api/pdf`, config);
    return res.json();
  };

  const getquote = async () => {
    const res = await fetch(`${baseUrl}api/quote`, config);
    return res.json();
  };
  
  const { data: currentFile, refetch, isLoading } = useQuery('dashboard_data', getFacts);
  const fileInput = useRef(null);

  useEffect(() => {
    setLink(currentFile?.link);
    getquote();
  }, [currentFile]);

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const pdfData = new FormData();
    if (file !== undefined) {
      pdfData.append('pdf', file);
    }
    try {
      setAddPdfLoading(true);
      const response = await axios.post(`${baseUrl}api/pdf-upload`, pdfData, config);
      toast.success(response?.data.message);
      setAddPdfLoading(false);
      refetch();
    } catch (error) {
      toast.error('Something went wrong');
      setAddPdfLoading(false);
      console.log(error);
      refetch();
    }

    // return response
  };
  const LinkUpload = async () => {
    const LinkData = new FormData();
    LinkData.append('link', link);
    LinkData.append('note', currentFile?.note);
    try {
      setAddLinkLoading(true);
      const response = await axios.post(`${baseUrl}api/pdf-upload`, LinkData, config);
      toast.success(response?.data.message);
      setAddLinkLoading(false);
      refetch();
    } catch (error) {
      toast.error('Something went wrong');
      setAddLinkLoading(false);
      console.log(error);
      refetch();
    }
  };
  const NoteUpload = async () => {
    const NoteData = new FormData();
    NoteData.append('note', editorData);
    NoteData.append('link', currentFile?.link);

    try {
      setAddNoteLoading(true);
      const response = await axios.post(`${baseUrl}api/pdf-upload`, NoteData, config);
      toast.success(response?.data.message);
      setAddNoteLoading(false);
      refetch();
    } catch (error) {
      toast.error('Something went wrong');
      setAddNoteLoading(false);
      console.log(error);
      refetch();
    }
  };

  const LinkRemove = async () => {
    try {
      setRemoveLinkLoading(true);
      const response = await axios.get(`${baseUrl}api/delete/link`, config);
      toast.success(response?.data.message);
      setRemoveLinkLoading(false);
      setLink('');
      refetch();
    } catch (error) {
      setLink('');
      toast.error('Something went wrong');
      setRemoveLinkLoading(false);
      console.log(error);
      refetch();
    }
  };
  const PDFRemove = async () => {
    try {
      setRemovePdfLoading(true);
      const response = await axios.get(`${baseUrl}api/delete/pdf`, config);
      toast.success(response?.data.message);
      setRemovePdfLoading(false);
      refetch();
    } catch (error) {
      toast.error('Something went wrong');
      setRemovePdfLoading(false);
      console.log(error);
      refetch();
    }
  };
  // REMOVE NOTE

  return (
    <>
      <Helmet>
        <title> Upload PDF</title>
      </Helmet>
      <Toaster />
      {isLoading ? (
        <div className="mydivss">
          <Circles height="80" width="80" color="#000" ariaLabel="circles-loading" wrapperStyle={{}} wrapperClass="" />
        </div>
      ) : (
        <Container>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Upload PDF
          </Typography>

          <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="flex-start" sx={{ mb: 5 }}>
            <Stack direction="column" spacing={1}>
              {currentFile?.data && (
                <Link target={'_blank'} to={currentFile?.data}>
                  <Button variant="contained">
                    <Icon icon="ic:baseline-remove-red-eye" className="uploadIconss" />
                    &nbsp; View Current PDF
                  </Button>
                </Link>
              )}
            </Stack>
            <Stack direction="column" spacing={4} sx={{ m: 5 }}>
              <LoadingButton size="medium" onClick={() => handleClick()} loading={addPdfLoading} variant="outlined">
                <Icon icon="material-symbols:cloud-upload" className="uploadIconss" />
                &nbsp; UPLOAD NEW PDF
              </LoadingButton>
              <input type="file" onChange={(e) => handleFileChange(e)} ref={fileInput} accept="application/pdf" />
            </Stack>

            <Stack direction="column" spacing={1}>
              {currentFile?.data && (
                <LoadingButton
                  size="medium"
                  loading={removePdfLoading}
                  onClick={() => PDFRemove()}
                  variant="contained"
                  color="error"
                >
                  <Icon icon="ic:baseline-delete-forever" className="uploadIconss" />
                  &nbsp; REMOVE CURRENT PDF
                </LoadingButton>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="flex-start" sx={{ mb: 5 }}>
            <Stack direction="column" spacing={1}>
              <TextField
                id="outlined-basic"
                label="Link"
                variant="outlined"
                onChange={(e) => setLink(e.target.value)}
                value={link}
                defaultValue={link}
              />
            </Stack>
            <Stack direction="column" spacing={4} sx={{ m: 5 }}>
              <LoadingButton size="medium" onClick={() => LinkUpload()} loading={addLinkLoading} variant="outlined">
                <Icon icon="material-symbols:add-link" className="uploadIconss" />
                &nbsp; ADD NEW LINK
              </LoadingButton>
            </Stack>

            <Stack direction="column" spacing={1}>
              {currentFile?.link && (
                <LoadingButton
                  size="medium"
                  loading={removeLinkLoading}
                  onClick={() => LinkRemove()}
                  variant="contained"
                  color="error"
                >
                  <Icon icon="ic:baseline-delete-forever" className="uploadIconss" />
                  &nbsp;REMOVE CURRENT LINK
                </LoadingButton>
              )}
            </Stack>
          </Stack>
          <MyEditor defaultValue={currentFile?.note} handlechange={getEditor} />
          <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="flex-start" sx={{ mb: 5 }}>
            <Stack direction="column" spacing={4} sx={{ m: 5 }}>
              <LoadingButton size="medium" onClick={() => NoteUpload()} loading={addNoteLoading} variant="outlined">
                <Icon icon="clarity:note-line" className="uploadIconss" />
                &nbsp;SAVE NOTE
              </LoadingButton>
            </Stack>

         
          </Stack>
        </Container>
      )}
    </>
  );
}
