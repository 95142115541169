const accessToken = localStorage.getItem('access_token');
export const config = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
    'Content-type': 'Application/json',
    Authorization: `Bearer ${accessToken}`,
  },
};

export const baseUrl ="https://uhsapi.dev-sh.xyz/"