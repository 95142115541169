import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// eslint-disable-next-line
const MyEditor = ({ defaultValue, handlechange }) => {
  return (
    <ReactQuill
      theme="snow"
      defaultValue={defaultValue}
      onChange={(e) => handlechange(e)}
      placeholder="Write Notes here..."
    />
  );
};

export default MyEditor;
