import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useQuery } from 'react-query';
import MUIDataTable from 'mui-datatables';
import { Circles } from 'react-loader-spinner';
import { Button } from '@mui/material';
import { config } from './header';
import Modals from './Modals';

export default function UserPage() {
  const getFacts = async () => {
    const res = await fetch('https://uhsapi.dev-sh.xyz/api/quote', config);
    return res.json();
  };
  // Using the hook
  const { data, isLoading } = useQuery('Table_data', getFacts
  // , {
    // onSuccess: (error) => {
    //   if (error.message === 'Unauthenticated.') {
    //     navigate('/login');
    //   }
    // },
  // }
  );

  const options = {
    filter: false,
    selectableRows: false,
    print: false,
  };

  const [newdata, setnewdata] = useState([]);

  const columns = [
    {
      name: 'first_name',
      label: 'First Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'square_feet',
      label:"Squarefeet",
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'square_feet_cost',
      label:"Square Feet Cost",
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'stairwell_basement',
      label:"Stairwell Basement",
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'stairwell_basement_cost',
      label:"Stairwell Basement Cost",
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'bedrooms',
      label:"Bedrooms",
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'bedrooms_cost',
      label:"Bedrooms Cost",
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'rec_rooms',
      label:"Rec Rooms",
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'rec_rooms_cost',
      label:"Rec Rooms Cost",
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'bathrooms',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'bathrooms_cost',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'doors',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'doors_cost',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'bar_or_kitchen',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'bar_or_kitchen_cost',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'fireplace',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'fireplace_cost',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'legal_sec_suit',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'legal_sec_suit_cost',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'basement_foot',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'basement_foot_cost',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'storage_rooms',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'storage_rooms_cost',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'basement_windows',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'basement_windows_cost',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'approximate_year',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'special_notes',
      options: {
        filter: true,
        sort: false,
        display: false,
        viewColumns: false,
        
      },
    },
    {
      name: 'phone',
      label: 'Details',
      //  options: {
      options: {
        filter: true,
        sort: false,
        download:false,
        // eslint-disable-next-line
        customBodyRender: (value, rowdata) => {
          return (
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                // console.log(data.data[rowdata.rowIndex])
                setnewdata(data.data[rowdata.rowIndex])
              }
            >
              <Modals news={newdata} />
            </Button>
          );
        },
      },
      //  }
    },
  ];

  return (
    <>
      <Helmet>
        <title>Qoutes</title>
      </Helmet>

      {isLoading ? (
        <div className="mydivss">
          <Circles height="80" width="80" color="#000" ariaLabel="circles-loading" wrapperStyle={{}} wrapperClass="" />
        </div>
      ) : (
        <>
          <MUIDataTable title={'Qoute List'} data={data?.data} columns={columns} options={options} />
        </>
      )}
    </>
  );
}
