import * as yup from 'yup';

export const prices = yup
  .object({
    square_feet_amount: yup
      .number()
      .typeError('square_feet_amount must be a valid number')
      .positive('square_feet_amount must be a positive number')
      .required(),
    stairwell_basement_amount: yup
      .number()
      .typeError('stairwell_basement_amount must be a valid number')
      .positive('stairwell_basement_amount must be a positive number')
      .required(),
    bedrooms_amount: yup
      .number()
      .typeError('bedrooms_amount must be a valid number')
      .positive('bedrooms_amount must be a positive number')
      .required(),
    rec_rooms_amount: yup
      .number()
      .typeError('rec_rooms_amount must be a valid number')
      .positive('rec_rooms_amount must be a positive number')
      .required(),
    bathrooms_amount: yup
      .number()
      .typeError('bathrooms_amount must be a valid number')
      .positive('bathrooms_amount must be a positive number')
      .required(),
    storage_rooms_amount: yup
      .number()
      .typeError('storage_rooms_amount must be a valid number')
      .positive('storage_rooms_amount must be a positive number')
      .required(),
    doors_amount: yup
      .number()
      .typeError('Linen Closet Amount must be a valid number')
      .positive('Linen Closet Amount must be a positive number')
      .required(),
    bar_or_kitchen_amount: yup
      .number()
      .typeError('bar_or_kitchen_amount must be a valid number')
      .positive('bar_or_kitchen_amount must be a positive number')
      .required(),
    fireplace_amount: yup
      .number()
      .typeError('fireplace_amount must be a valid number')
      .positive('fireplace_amount must be a positive number')
      .required(),
    legal_sec_suit_amount: yup
      .number()
      .typeError('legal_sec_suit_amount must be a valid number')
      .positive('legal_sec_suit_amount must be a positive number')
      .required(),
    basement_foot_amount: yup
      .number()
      .typeError('9  Foor Ceiling Amount must be a valid number')
      .positive('9  Foor Ceiling Amount must be a positive number')
      .required(),
    basement_windows_amount: yup
      .number()
      .typeError('basement_windows_amount must be a valid number')
      .positive('basement_windows_amount must be a positive number')
      .required(),
    profit_amount: yup
      .number()
      .typeError('profit_amount must be a valid number')
      .positive('profit_amount must be a positive number')
      .required(),
  })
  .required();
