import { Circles } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography } from '@mui/material';
// sections
import { AppWidgetSummary } from '../sections/@dashboard/app';
import { config, baseUrl } from './header';

export default function DashboardAppPage() {
  const getFacts = async () => {
    const res = await fetch(`${baseUrl}api/quote`, config);
    return res.json();
  };
  // Using the hook
  const { data, isLoading, refetch } = useQuery('dashboard_data', getFacts);
  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard</title>
      </Helmet>

      {isLoading ? (
        <div className="mydivss">
          <Circles height="80" width="80" color="#000" ariaLabel="circles-loading" wrapperStyle={{}} wrapperClass="" />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Welcome back
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="Qoutes" total={data?.total_count} icon={'ant-design:android-filled'} />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
