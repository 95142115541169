import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { Circles } from 'react-loader-spinner';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { config, baseUrl } from './header';
import { prices } from './PriceSchema';

// eslint-disable-next-line
const ManagePrice = () => {
  const [btnLoading, setbtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(prices),
  });

  const onSubmit = async (data) => {
    try {
      setbtnLoading(true);
      const result = await axios.post(`${baseUrl}api/quote/amount/update`, data, config);
      toast.success(result?.data?.message);
      setbtnLoading(false);
    } catch (error) {
      toast.error('Something Wents Wrong!!!');
      console.log(error);
      setbtnLoading(false);
    }
  };
  const getFacts = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${baseUrl}api/quote/amount/show`, config);
      setData(res?.data);
      reset(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFacts();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Toaster />
      {isLoading ? (
        <div className="mydivss">
          <Circles
            height="80"
            width="80"
            color="#000"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            // visible={true}
          />
        </div>
      ) : (
        <>
          <h1 style={{ margin: '10px' }}>Manage Prices</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              id="outlined-basic"
              label={'Square Feet Amount'}
              variant="outlined"
              {...register('square_feet_amount')}
              defaultValue={data?.data?.square_feet_amount}
              sx={{ m: 2 }}
              helperText={errors?.square_feet_amount?.message}
            />

            <TextField
              id="outlined-basic"
              label={'Stairwell basement Amount'}
              variant="outlined"
              type="number"
              {...register('stairwell_basement_amount')}
              defaultValue={data?.data?.stairwell_basement_amount}
              helperText={errors?.stairwell_basement_amount?.message}
              sx={{ m: 2 }}
            />

            <TextField
              id="outlined-basic"
              label={'Bedrooms Amount'}
              variant="outlined"
              {...register('bedrooms_amount')}
              defaultValue={data?.data?.bedrooms_amount}
              sx={{ m: 2 }}
              helperText={errors?.bedrooms_amount?.message}
            />
            <TextField
              id="outlined-basic"
              label={'Rec Rooms Amount'}
              variant="outlined"
              {...register('rec_rooms_amount')}
              defaultValue={data?.data?.rec_rooms_amount}
              sx={{ m: 2 }}
              helperText={errors?.rec_rooms_amount?.message}
            />
            <TextField
              id="outlined-basic"
              label={'Bathroom Amount'}
              variant="outlined"
              {...register('bathrooms_amount')}
              defaultValue={data?.data?.bathrooms_amount}
              sx={{ m: 2 }}
              helperText={errors?.bathrooms_amount?.message}
            />
            <TextField
              id="outlined-basic"
              label={'Storage Room Amount'}
              variant="outlined"
              {...register('storage_rooms_amount')}
              defaultValue={data?.data?.storage_rooms_amount}
              sx={{ m: 2 }}
              helperText={errors?.storage_rooms_amount?.message}
            />
            <TextField
              id="outlined-basic"
              label={'Linen Closet Amount'}
              variant="outlined"
              {...register('doors_amount')}
              defaultValue={data?.data?.doors_amount}
              sx={{ m: 2 }}
              helperText={errors?.doors_amount?.message}
            />
            <TextField
              id="outlined-basic"
              label={'Bar or Kitchen Amount'}
              variant="outlined"
              {...register('bar_or_kitchen_amount')}
              defaultValue={data?.data?.bar_or_kitchen_amount}
              sx={{ m: 2 }}
              helperText={errors?.bar_or_kitchen_amount?.message}
            />
            <TextField
              id="outlined-basic"
              label={'Fireplace Amount'}
              variant="outlined"
              {...register('fireplace_amount')}
              defaultValue={data?.data?.fireplace_amount}
              sx={{ m: 2 }}
              helperText={errors?.fireplace_amount?.message}
            />
            <TextField
              id="outlined-basic"
              label={'Legal Sec Suit Amount'}
              variant="outlined"
              {...register('legal_sec_suit_amount')}
              defaultValue={data?.data?.legal_sec_suit_amount}
              sx={{ m: 2 }}
              helperText={errors?.legal_sec_suit_amount?.message}
            />
            <TextField
              id="outlined-basic"
              label={'9  Foor Ceiling Amount'}
              variant="outlined"
              {...register('basement_foot_amount')}
              defaultValue={data?.data?.basement_foot_amount}
              sx={{ m: 2 }}
              helperText={errors?.basement_foot_amount?.message}
            />
            <TextField
              id="outlined-basic"
              label={'Basement Windows Amount'}
              variant="outlined"
              {...register('basement_windows_amount')}
              defaultValue={data?.data?.basement_windows_amount}
              helperText={errors?.basement_windows_amount?.message}
              sx={{ m: 2 }}
            />
            <TextField
              id="outlined-basic"
              label={'Profit Amount'}
              variant="outlined"
              {...register('profit_amount')}
              defaultValue={data?.data?.profit_amount}
              helperText={errors?.profit_amount?.message}
              sx={{ m: 2 }}
            />
            <LoadingButton
              size="large"
              type="submit"
              loading={btnLoading}
              sx={{ m: 2 }}
              variant="contained"
              color="primary"
            >
              Save Changes
            </LoadingButton>

            {/* <input type="submit" /> */}
          </form>
        </>
      )}
    </>
  );
};

export default ManagePrice;
