// component
import { Icon } from '@iconify/react';

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <Icon icon="carbon:analytics" className="menuIcons" />,
  },
  {
    title: 'Qoutes',
    path: '/dashboard/user',
    icon: <Icon icon="mdi:user" className="menuIcons" />,
  },
  {
    title: 'PDF Upload',
    path: '/dashboard/pdf-upload',
    icon: <Icon icon="teenyicons:pdf-solid" className="menuIcons" />,
  },
  {
    title: 'Mange Price',
    path: '/dashboard/manage_price',
    icon: <Icon icon="solar:tag-price-bold-duotone" className="menuIcons" />,
  },

];

export default navConfig;
